<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <svg
      width="80" height="80"
      style="min-width: 80px; min-height: 80px;"
      viewBox="0 0 80 80" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#2F57E9" fill-opacity="0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#2F57E9" fill-opacity="0.2"/>
      <g clip-path="url(#clip0_720_16656)">
        <path d="M62.1722 33.4785L39.9984 47.8262L17.8245 33.4785V11.3046C17.8092 10.5995 18.3684 10.0156 19.0735 10.0003C19.092 9.99994 19.1104 9.99994 19.1289 10.0003H60.8678C61.5729 9.98502 62.1569 10.5442 62.1722 11.2493C62.1725 11.2678 62.1725 11.2862 62.1722 11.3047V33.4785Z" fill="#F0F2F8"/>
        <path d="M17.826 33.479L10 28.2616L17.826 20.4355V33.479Z" fill="#F6B445"/>
        <path d="M69.9979 28.2616L62.1719 33.479V20.4355L69.9979 28.2616Z" fill="#F6B445"/>
        <path d="M62.1733 33.3608L69.9993 28.2607V64.7823C69.9969 66.1658 69.4485 67.4924 68.4733 68.4737L59.5647 59.5651L45.2168 45.2172L44.8516 44.6563L62.1733 33.3608Z" fill="#FED049"/>
        <path d="M59.5663 59.5649L68.4749 68.4735C67.4936 69.4487 66.1671 69.9971 64.7836 69.9996H15.2187C13.8352 69.9971 12.5087 69.4487 11.5273 68.4735L20.436 59.5649L34.7837 45.2172L35.1489 44.6562L40.0011 47.8258L44.8532 44.6562L45.2185 45.2172L59.5663 59.5649Z" fill="#F6B445"/>
        <path d="M35.1477 44.6563L34.7824 45.2172L20.4347 59.5649L11.5261 68.4735C10.5509 67.4922 10.0024 66.1657 10 64.7822V28.2607L35.1477 44.6563Z" fill="#FED049"/>
        <path d="M54.3474 41.3042C62.9918 41.3042 69.9995 34.2965 69.9995 25.6521C69.9995 17.0077 62.9918 10 54.3474 10C45.703 10 38.6953 17.0077 38.6953 25.6521C38.6953 34.2965 45.703 41.3042 54.3474 41.3042Z" fill="#2F57E9"/>
        <path d="M54.749 32.3794C54.0286 32.3794 53.4446 31.7954 53.4446 31.0751V21.7724L51.5599 23.0299C50.9606 23.4297 50.1506 23.268 49.7507 22.6685C49.3509 22.0692 49.5126 21.2592 50.1121 20.8594L54.0251 18.2507C54.6243 17.8509 55.4343 18.0126 55.834 18.6118C55.9771 18.8262 56.0534 19.0782 56.0534 19.336V31.0751C56.0534 31.7954 55.4694 32.3794 54.749 32.3794Z" fill="#F1F2F2"/>
      </g>
      <defs>
        <clipPath id="clip0_720_16656">
          <rect width="60" height="60" fill="white" transform="translate(10 10)"/>
        </clipPath>
      </defs>
    </svg>

    <p>
      {{ $t('pop-up.email-password.to') }}

      <br>

      <span>
        {{ _getEmail }}
      </span>
    </p>

    <BlueButton
      :text="
        seconds === 0 ? $t('pop-up.email-password.resend')
          : $t('pop-up.email-password.resend-timer', { seconds: format(seconds) })
      "
      :disabled="loading || seconds > 0"
      @click="resend()"
    />
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    BlueButton
  },
  data () {
    return {
      timer: null,
      seconds: 0,
      loading: false
    }
  },
  mounted () {
    this.startTimer()
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  computed: {
    _getEmail () {
      return this.$store.state.popUp.active.email
    }
  },
  methods: {
    startTimer () {
      this.seconds = 59

      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds = this.seconds - 1
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    async resend () {
      this.loading = true
      const formData = new FormData()
      formData.append('email', this._getEmail)

      try {
        const resp = await this.$axios.post(
          '/auth/reset-password', formData)

        if (resp.status === 200) {
          this.startTimer()
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    format (value) {
      if (value < 10) {
        return `0${value}`
      }

      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 430px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
  border-radius: 15px;

  .container__control {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  p {
    margin-top: 25px;
    text-align: center;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: #202539;
    font-family: SemiBold;

    span {
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      color: rgb(var(--primary-color));
      font-family: SemiBold;
    }
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
